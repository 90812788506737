<template>
  <div class="container">

    <h2>Fazer Login</h2>

    <b-form @submit.prevent="onSubmit" v-if="show" class="col-4 offset-4 border p-3">

      <b-form-group
          id="input-group-1"
          label="E-mail:"
          label-for="input-1">
        <b-form-input
            id="input-1"
            v-model="form.email"
            type="email"
            placeholder="Digite o e-mail"
            required>
        </b-form-input>
      </b-form-group>

      <b-form-group
          id="input-group-1"
          label="Senha:"
          label-for="input-1">
        <b-form-input
            id="input-1"
            v-model="form.password"
            type="password"
            placeholder="Digite a senha"
            required>
        </b-form-input>
      </b-form-group>

      <b-button type="submit" variant="primary">Entrar</b-button>
      <router-link :to="{name:'register'}">Cadastre-se</router-link>
    </b-form>

  </div>
</template>

<script>



export default {
  data() {
    return {
      form: {
        email   : '',
        password: ''
      },
      show: true,
    }
  },
  created() {

  },
  methods: {
    onSubmit() {
      this.$store.dispatch('login', this.form)
          .then(() => this.$router.push({name: 'admin'}))
          .catch(() => {
            console.log("Credenciais inválidas!");
          });
    }
  }
}
</script>

<style scoped>
form {
  border-radius : 5px;
  text-align    : left !important;
}

</style>
