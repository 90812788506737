<template>
  <div class="preload" v-show="preloader">
    <img src="/imgs/preloader.gif" />
  </div>
</template>

<script>
export default {
  computed:{
    preloader(){
      return this.$store.state.preloader.loading
    }
  }
}
</script>

<style scoped>
.preload{
  background-color: #fff;
  position: absolute;
  z-index: 9999;
  opacity: 1;
  width: 100%;
  height: 100%;
  margin: 0;
  text-align: center;
}
.preload img{
  max-width: 150px;
  padding-top: 20%;
}
</style>
