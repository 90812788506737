import axios from "axios";
import { NAME_TOKEN, URL_BASE } from "@/config/configs";

const actions = {
    register(context, params) {

        context.commit('PRELOADER', true);

        // eslint-disable-next-line no-unused-vars
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE}/register`, params)
                .then(response => {

                    context.commit('AUTH_USER_OK', response.data.user)

                    const token = response.data.token

                    // SALVA O TEKEN NO LOCALSTORAGE DO NAVEGADOR
                    localStorage.setItem(NAME_TOKEN, token)
                    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

                    resolve();
                })
                .catch(error => reject(error.response.data))
                .finally(context.commit('PRELOADER', false))
        })
    },

    update(context, params) {

        context.commit('PRELOADER', true);

        // eslint-disable-next-line no-unused-vars
        return new Promise((resolve, reject) => {
            axios.put(`${URL_BASE}/update`, params)
                .then(response => {

                    context.commit('AUTH_USER_OK', response.data.user)

                    resolve();
                })
                .catch(error => reject(error.response.data))
                .finally(context.commit('PRELOADER', false))
        })
    }
}

export default {
    actions
}
