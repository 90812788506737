import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '@/vuex/store'

import Login from '@/components/login/Login'
import Home from '@/components/home/Home'
import Admin from "@/components/admin/Admin";
import Register from "@/components/user/Register";
import Profile from "@/components/user/Profile";

Vue.use(VueRouter)

const routes = [
    {
        path     : '/',
        name     : 'home',
        component: Home
    },
    {
        path     : '/login',
        name     : 'login',
        component: Login,
        meta     : { auth:false }
    },
    {
        path     : '/cadastrar',
        name     : 'register',
        component: Register,
        meta     : { auth:false }
    },
    {
        path     : '/meu-perfil',
        name     : 'profile',
        component: Profile,
        meta     : { auth:true }
    },
    {
        path     : '/admin',
        component: Admin,
        meta     : {auth: true},
        children : [
            { path: '/', component: Admin, name: 'admin' }
        ]
    }

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {

    /**
     * VERIFICAÇÃO PARA ROTAS NORMAIS
     */
    if (to.meta.auth && !store.state.auth.authenticated) {

        store.commit('CHANGE_URL_BACK', to.name)

        return router.push({name: 'login'})
    }

    /**
     * VERIFICAÇÃO DE AUTENTICAÇÃO PARA ROTA PAI
     */
    if (to.matched.some(record => record.meta.auth) && !store.state.auth.authenticated) {

        store.commit('CHANGE_URL_BACK', to.name)

        return router.push({name: 'login'})
    }


    // eslint-disable-next-line no-prototype-builtins
    if(to.meta.hasOwnProperty('auth') && !to.meta.auth && store.state.auth.authenticated) {
        return router.push({name: 'admin'})
    }


    next();

})

export default router
