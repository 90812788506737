import Vue from 'vue'
import Vuex from 'vuex'

import auth from '@/vuex/modules/auth/auth'
import preloader from "@/vuex/modules/preloader/preloader";
import profile from '@/vuex/modules/users/profile'

Vue.use(Vuex)

// eslint-disable-next-line no-unused-vars
const store = new Vuex.Store({

    modules: {
        auth,
        preloader,
        profile
    }

})


export default store
