<template>
  <div>
    <b-form-group
        id="input-group-1"
        label="Nome:"
        label-for="input-1"
        :class="[{'has-error':errors.name}]">
      <b-form-input
          id="input-1"
          v-model="form.name"
          type="text"
          placeholder="Digite o seu nome">
      </b-form-input>
      <div v-if="errors.name">***{{ errors.name[0] }}</div>
    </b-form-group>

    <b-form-group
        id="input-group-1"
        label="E-mail:"
        label-for="input-1"
        :class="[{'has-error':errors.email}]">
      <b-form-input
          id="input-1"
          v-model="form.email"
          type="email"
          placeholder="Digite o e-mail">
      </b-form-input>
      <div v-if="errors.email">***{{ errors.email[0] }}</div>
    </b-form-group>

    <b-form-group
        id="input-group-1"
        label="Senha:"
        label-for="input-1"
        :class="[{'has-error':errors.password}]">
      <b-form-input
          id="input-1"
          v-model="form.password"
          type="password"
          placeholder="Digite a senha">
      </b-form-input>
      <div v-if="errors.password">***{{ errors.password[0] }}</div>
    </b-form-group>

    <b-button type="submit" variant="primary">Cadastrar</b-button>
    <router-link :to="{name:'login'}">Login</router-link>
  </div>
</template>

<script>
export default {
  props: ['form', 'errors']
}
</script>

<style scoped>
.has-error {
  color: #e01010;
}

.has-error input{
  border: 1px solid #e01010
}
</style>
