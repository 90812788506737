<template>
  <div class="container">

    <h2>Fazer Cadastro</h2>

    <b-form @submit.prevent="onSubmit" class="col-4 offset-4 border p-3">

      <user-form :form="form" :errors="errors"></user-form>

    </b-form>

  </div>
</template>

<script>

import UserForm from "@/components/user/UserForm";

export default {
  data() {
    return {
      form: {
        name    : '',
        email   : '',
        password: ''
      },
      errors: {}
    }
  },
  methods: {
    onSubmit() {
      this.$store.dispatch('register', this.form)
          .then(() => this.$router.push({name: 'admin'}))
          .catch(response => {
            this.errors = response.errors
          })
    }
  },
  components: {
    UserForm
  }
}
</script>

<style scoped>
form {
  border-radius : 5px;
  text-align    : left !important;
}
</style>
