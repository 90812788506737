<template>
  <div id="app">

    <preloader></preloader>

    <div id="nav">
      <router-link to="/">Home</router-link> |

      <div class="nav-item" v-if="me.name" :to="{name: 'admin'}">
        <b-dropdown id="dropdown-1" :text="'Olá ' + me.name" variant="success" class="m-md-2">
          <b-dropdown-item>
            <router-link :to="{name: 'admin'}">Admin</router-link>
          </b-dropdown-item>
          <b-dropdown-item>
            <router-link :to="{name: 'profile'}">Meu Perfil</router-link>
          </b-dropdown-item>
          <b-dropdown-item @click.prevent="logout">Sair</b-dropdown-item>
        </b-dropdown>
      </div>

      <router-link v-else :to="{name: 'login'}">Login</router-link>
    </div>
    <router-view/>
  </div>
</template>

<script>

  export default {
    computed: {
      me() {
        return this.$store.state.auth.me
      }
    },
    methods: {
      logout() {
        this.$store.dispatch('logout')
      }
    }
  }

</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }

  div{
    display: inline;
    list-style: none;
  }

}


</style>
