import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from '@/vuex/store'
import axios from "axios";

import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
import {NAME_TOKEN} from "@/config/configs";

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'


/**
 * COMPONENTES GLOBAIS
 */
Vue.component('preloader', require('./components/all/Preloader').default);

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.config.productionTip = false

const token = localStorage.getItem(NAME_TOKEN);

if (token)
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')

store.dispatch('checkLogin')
    .then(() => {
        router.push({ name: store.state.auth.urlBack })
    })
    .catch(() => {
        return false
    })
