<template>
  <div class="container">

    <h2>Atualizar Perfil</h2>

    <b-form @submit.prevent="onSubmit" class="col-4 offset-4 border p-3">

      <user-form :form="form" :errors="errors"></user-form>

    </b-form>

  </div>
</template>

<script>

import UserForm from "@/components/user/UserForm";

export default {
  computed: {
    form() {
      return this.$store.state.auth.me
    }
  },
  data() {
    return {
      errors: {}
    }
  },
  methods: {
    onSubmit() {
      this.$store.dispatch('update', this.form)
          .then(() => this.$router.push({name: 'admin'}))
          .catch(response => {
            this.errors = response.errors
          })
    }
  },
  components: {
    UserForm
  }
}
</script>

<style scoped>
form {
  border-radius : 5px;
  text-align    : left !important;
}

.has-error {
  color: #e01010;
}

.has-error input{
  border: 1px solid #e01010
}
</style>
