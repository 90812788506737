import axios from "axios";
import {NAME_TOKEN} from "@/config/configs";
import {URL_BASE} from "@/config/configs";
import router from '@/router'


export default {
    state    : {
        me           : {},
        authenticated: false,
        urlBack: 'home',
    },
    mutations: {
        AUTH_USER_OK(state, user) {
            state.authenticated = true;
            state.me            = user;
        },

        CHANGE_URL_BACK(state, url) {
            state.urlBack = url;
        },

        AUTH_USER_LOGOUT(state) {
            state.me            = {}
            state.authenticated = false
            state.urlBack       = 'home'

            router.push({ name: 'home'})
        }
    },
    actions  : {
        login(context, params) {

            context.commit('PRELOADER', true);

            return axios.post(`${URL_BASE}/auth`, params)
                .then(res => {
                    context.commit('AUTH_USER_OK', res.data.user)

                    const token = res.data.token

                    // SALVA O TEKEN NO LOCALSTORAGE DO NAVEGADOR
                    localStorage.setItem(NAME_TOKEN, token)
                    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

                })
                //.catch(errors => console.log(errors))
                // eslint-disable-next-line no-undef
                .catch()
                .finally(() => {
                    setTimeout(function () {
                        context.commit('PRELOADER', false)
                    }, 1000)
                })
        },

        checkLogin(context) {

            context.commit('PRELOADER', true);

            return new Promise((resolve, reject) => {

                const token = localStorage.getItem(NAME_TOKEN);

                if (!token){
                    context.commit('PRELOADER', false)
                    return reject();
                } else {

                    axios.get(`${URL_BASE}/me`)
                        .then(res => {

                            context.commit('AUTH_USER_OK', res.data.user)

                            resolve();
                        })
                        .catch(() => reject())
                        .finally(() => {
                            //setTimeout(function () {
                                context.commit('PRELOADER', false)
                            //}, 3000)
                        })
                }

            })
        },


        logout(context) {
            localStorage.removeItem(NAME_TOKEN)

            context.commit('AUTH_USER_LOGOUT')
        }
    }
}
