export default {
    state:{
        loading: false
    },
    mutations:{
        PRELOADER (state, status){
            state.loading = status
        }
    }
}
